import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private titleService: Title, private metaService: Meta) {}

  updateMetaData(title: string, keywords: string, description: string) {
    this.titleService.setTitle(title + ' - ' + environment.meta.title);
    this.metaService.updateTag({
      name: 'keywords',
      content: keywords + ', ' + environment.meta.keywords,
    });
    this.metaService.updateTag({
      name: 'description',
      content: description + ', ' + environment.meta.description,
    });
  }

  updateDefaultMetaData() {
    this.updateMetaData(
      environment.meta.title,
      environment.meta.keywords,
      environment.meta.description
    );
  }
}

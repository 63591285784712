export const environment = {
  production: true,
  apiUrl: 'https://portal.gugudata.io',
  apihost: 'api.gugudata.io',
  turnstileKey: '0x4AAAAAAASSss7vJeDNN_iD',
  meta: {
    title: 'GuGuData | Premier Source for Professional Data',
    keywords:
      'GuGuData, data, professional data, high-quality data, accurate data, up-to-date data, businesses, researchers, developers',
    description:
      'GuGuData is a premier source for professional data. We provide high-quality, accurate, and up-to-date data for businesses, researchers, and developers.',
  },
  stripe: {
    publishableKey:
      'pk_live_51Osy41EzF3zoNjqCd8HaMZcR6NoY9CFwti6643vLIu4bFQKrchmfCR6jrpefp11YrJGmcaVgY7GugInxA2sDrjDP00E4LxA4DD',
  },
  sentryDSN:
    'https://61dbf9a644676adf5197386e79b8efc4@us.sentry.io/4506699180736512',
};
